.banner  {
    width: 100%;
    height: 223px;
    overflow: hidden;
    border-radius: 25px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        filter: brightness(70%);
    }

    h3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 40px;
        font-weight: 200;
    }
}

@include mobile-only {
    .banner {
        height: 111px;
        border-radius: 10px;

        h3 {
            font-size: 25px;
            width: 65%;
            left: 40%;
        }
    }
}