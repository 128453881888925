.carousel-container {
    position: relative;
    width: 100%;
    height: 415px;
    border-radius: 25px;
    overflow: hidden;
    background-color: rgb(236, 236, 236);

    .slide {
        display: none;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        animation: fade 1.5s;
    }

    .numberText {
        position: absolute;
        bottom: 30px;
        color: white;
        width: 100%;
        font-weight: 100;
        font-size: 20px;
        text-align: center;
    }

    .control {
        position: absolute;
        top: 50%;
        font-size: 60px;
        transform: translateY(-50%);
        color: white;

        &:hover {
            color: rgb(255, 220, 220);
            cursor: pointer;
        }

        &.right {
            right: 20px;
        }

        &.left {
            left: 20px;
        }
    }
}

@keyframes fade {
    from {opacity: 0}
    to {opacity: 1}
}

@include mobile-only {
    .carousel-container {
        height: 255px;
        border-radius: 10px;

        .numberText {
            display: none;
        }
        .control {
            font-size: 30px;
        }
    }
}