.card {
    width: 340px;
    height: 340px;
    background-color: rgb(247, 162, 162);
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: 0.1s transform;

    @include mobile-only {
        height: 255px;
        width: 100%;
        margin: 5px 0 5px 0;
    }

    @include desktop-only {
        
        box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.4);

        &:hover {
            transform: scale(1.1);
        }
    }

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba(0,0,0,1) 3%, rgba(0,0,0,0) 50%);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }

    p {
        position: absolute;
        bottom: 20px;
        left: 20px;
        z-index: 10;
        color: white;
        width: 200px;
    }

}