.rating {
    font-size: 20px;
    width: 160px;
    display: flex;
    justify-content: space-between;
    color: #E3E3E3;

    .red {
        color: $primary-color;
    }
}

@include mobile-only {
    .rating {
        font-size: 16px;
        width: 110px;
    }
}