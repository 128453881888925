.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    img{
        width: 200px;
    }
}

.nav-bar {
    width: 200px;

    ul {
        display: flex;
        justify-content: space-between;
    }
    a {
        text-decoration: none;
        color: $primary-color;
    }
    .active{
        border-bottom: solid 2px $primary-color;
    }
}

@include mobile-only {
    .nav-bar {
        width: 160px;
    }

    .header {
        margin-bottom: 20px;
        img {
            width: 130px;
        }
    }

}