body {
    position: relative;
}

.footer {
    background-color: black;
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 200px;
    left: 0;
    padding: 60px;

    p {
        margin-top: 45px;
    }
}