#about {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dropdown {
        margin-top: 30px;
        width: 80%;
    }
}

@include mobile-only {
    #about {
        .banner {
            height: 255px;
        }
    }
}