.container-pagenotfound {
    color: $primary-color;
    display: flex;
    flex-direction: column;
    text-align: center;

    h1{
        font-size: 250px;
    }

    a{
        color: $primary-color;
        margin-top: 100px;
    }
}

@include mobile-only {
    .container-pagenotfound {
        padding-top: 100px;
        h1 {
            font-size: 100px;
        }
        h2 {
            font-size: 20px;
            font-weight: 100;
        }
    }
}