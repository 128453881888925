#lodge {

    .infos {
        position: relative;
        margin-top: 40px;
    }

    h3 {
        font-size: 35px;
        font-weight: 400;
        color: $primary-color;
        max-width: 60%;
    }
    h4 {
        margin-top: 10px;
        font-weight: 100;
        font-size: 17px;
        color: $primary-color;
    }

    .tags {
        display: flex;
        margin-top: 15px;
        flex-wrap: wrap;

        li {
            background-color: $primary-color;
            padding: 7px 0 7px 0;
            color: white;
            margin-right: 15px;
            margin-bottom: 5px;
            font-size: 11px;
            font-weight: 100;
            width: 120px;
            text-align: center;
            border-radius: 10px;
        }
    }

    .profil-rate {
        position: absolute;
        right: 0;
        top: 0;
        height: 105px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .profil {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $primary-color;
            width: 150px;
    
            img {
                border-radius: 100%;
                width: 60px;
            }
        }
    }

    .dropdown {
        margin-top: 30px;
        width: 48%;
    }

    .details {
        display: flex;
        justify-content: space-between;
    }
}

@include mobile-only {
    #lodge {
        .infos {
            margin-top: 10px;
        }

        h3 {
            font-size: 20px;
        }

        .profil-rate {
            position: initial;
            flex-direction: row-reverse;
            align-items: center;
            height: 75px;

            .profil {
                width: 115px;
                font-weight: 100;
                img {
                    width: 50px;
                }
            }
        }

        .details {
            flex-direction: column;
            .dropdown {
                margin: 0 0 20px 0;
            }
        }
    }
}