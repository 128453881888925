.cards-container {
    margin-top: 50px;
    background-color: #F6F6F6;
    width: 100%;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

@include mobile-only {
    .cards-container {
        padding: 0;
        background-color: #ffffff00;
        margin-top: 20px;
        flex-direction: column;
    }
}
