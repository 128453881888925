.dropdown {
    .dropdown-button {
        background-color: $primary-color;
        color: white;
        height: 35px;
        padding: 8px 0 5px 15px;
        border-radius: 5px;
        cursor: pointer;
        position: relative;

        .arrow {
            position: absolute;
            color: white;
            top: 5px;
            right: 16px;
            font-size: 20px;
            transform: rotate(90deg);
            transition: transform 0.2s linear;
        }
    }

    .dropdown-content {
        background-color: #F6F6F6;
        padding: 15px;
        color: $primary-color;
        font-weight: 200;
        border-radius: 5px;
        opacity: 0;
        display: none;
    }

    &.open {
        .dropdown-content{
            display: block;
            animation: fade-in 0.5s linear;
            opacity: 1;
        }
        .arrow {
            transform: rotate(270deg);
        }
    }
}

@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@include mobile-only {
    .dropdown {
        width: 100% !important;
    }
}